<!--
  -  Copyright (C) Healabs 2022 - All Rights Reserved
  -  Unauthorized copying of this file, via any medium is strictly prohibited
  -  Proprietary and confidential
  -
  -->
<template>
  <v-container fluid class="pa-0 white h-100" :class="$vuetify.breakpoint.mdAndDown ? 'bg-mobile' : ''">
    <validate-account v-if="!temporaryCode" :username.sync="username" @validate="validate" />
    <set-new-password v-else @submit="submit" />
  </v-container>
</template>

<script>
  import ValidateAccount from '@/modules/auth/ValidateAccount'
  import SetNewPassword from '@/modules/auth/SetNewPassword'
  import {initPasswordWithCode} from '@/modules/auth/api'

  export default {
    name: 'InitPassword',
    components: {SetNewPassword, ValidateAccount},
    data() {
      return {
        username: this.$route.query.email,
        temporaryCode: null
      }
    },
    methods: {
      validate(temporaryCode) {
        this.temporaryCode = temporaryCode
      },
      async submit(password, done) {
        try {
          await initPasswordWithCode(this.username, this.temporaryCode, password)
          await this.$router.push({name: 'login', query: {hint: this.username }})
        } catch (error) {
          this.$root.$emit('error', error)
        } finally {
          done()
        }
      }
    }
  }
</script>

