<!--
  -  Copyright (C) Healabs 2022 - All Rights Reserved
  -  Unauthorized copying of this file, via any medium is strictly prohibited
  -  Proprietary and confidential
  -
  -->
<template>
  <v-row class="fill-height" no-gutters justify="center" align="center">
    <v-col class="d-none d-lg-flex fill-height flex-column justify-center" cols="12" lg="6">
      <v-img :src="backGroundImage" />
    </v-col>
    <v-col cols="12" lg="6">
      <v-card class="mx-auto" color="transparent" flat min-width="320" width="400">
        <v-form v-model="valid" @submit.prevent="submit">
          <v-col cols="12">
            <v-img :src="require('@/assets/img/maPread_logo_color.svg')" max-width="250px" width="350" class="mb-3 mx-auto" max-height="70px" contain />
          </v-col>
          <v-col cols="12">
            <v-alert class="text-caption font-alt mb-0" color="secondary" dark dense>
              <font-awesome-icon :icon="['fal','lock-alt']" class="white--text mr-2" size="1x" />
              Création d'un mot de passe pour votre compte
            </v-alert>
          </v-col>
          <v-col cols="12">
            <v-text-field v-model="newpassword" :maxlength="200" :rules="passwordRules" dense hide-details="auto"
                          label="Nouveau mot de passe"
                          outlined
                          :type="showPassword ? 'text' : 'password'"
                          :append-icon="showPassword ? '$eyeSlash' : '$eye'"
                          @click:append="showPassword = !showPassword"
            />
          </v-col>
          <v-col cols="12">
            <v-text-field v-model="confirmPassword" :maxlength="200" :rules="[passwordConfirmationRule]" dense
                          :disabled="!newpassword && !confirmPassword"
                          hide-details="auto"
                          label="Confirmer le nouveau mot de passe"
                          outlined
                          :type="showConfirmPassword ? 'text' : 'password'"
                          :append-icon="showConfirmPassword ? '$eyeSlash' : '$eye'"
                          @click:append="showConfirmPassword = !showConfirmPassword"
            />
          </v-col>

          <v-card-actions class="d-flex justify-end">
            <v-btn small type="button" :to="{name: 'login'}">Annuler</v-btn>
            <v-btn :loading="loading" :disabled="loading || !valid" color="primary" small type="submit">Enregistrer</v-btn>
          </v-card-actions>
        </v-form>
      </v-card>
    </v-col>
  </v-row>
</template>

<script>

  import {mapState} from 'vuex'

  export default {
    name: 'SetNewPassword',
    data() {
      return {
        oldpassword: '',
        valid: false,
        loading: false,
        passwordError: false,
        newpassword: '',
        confirmPassword: '',
        showPassword: false,
        showConfirmPassword: false,
        passwordRules: [
          v => !!v || 'Mot de passe requis',
          v => (v && v.length >= 8) || 'Votre mot de passe doit contenir 8 caractères',
          v => /(?=.*\d)/.test(v) || 'Votre mot de passe doit contenir un chiffre',
          v => /(?=.*[A-Z])/.test(v) || 'Votre mot de passe doit contenir une majuscule',
          v => /(?=.*[a-z])/.test(v) || 'Votre mot de passe doit contenir une minuscule'
        ]
      }
    },
    computed: {
      ...mapState('layout', ['customDomain']),
      backGroundImage() {
        return this.customDomain !== '' ? '/img/assets/'+this.customDomain+'/login-default-bg.jpg' : require('@/assets/img/login-default-bg.png')
      },
      passwordConfirmationRule(){
        return this.newpassword === this.confirmPassword || 'Les mots de passe ne correspondent pas'
      }
    },
    methods: {
      submit() {
        if(!this.valid) {
          return
        }
        this.loading = true
        this.$emit('submit', this.newpassword, () => {
          this.loading = false
        })
      }
    }
  }
</script>
