<!--
  -  Copyright (C) Healabs 2022 - All Rights Reserved
  -  Unauthorized copying of this file, via any medium is strictly prohibited
  -  Proprietary and confidential
  -
  -->
<template>
  <v-row no-gutters class="fill-height" justify="center" align="center">
    <v-col cols="12" lg="6" class="d-none d-lg-flex fill-height flex-column justify-center">
      <v-img :src="backGroundImage" />
    </v-col>
    <v-col cols="12" lg="6">
      <v-card flat color="transparent" class="mx-auto" min-width="320" width="400">
        <v-form ref="validaccount" lazy-validation @submit.prevent="submit">
          <v-col cols="12">
            <v-img :src="require('@/assets/img/maPread_logo_color.svg')" max-width="250px" width="350" class="mb-3 mx-auto" max-height="70px" contain />
            <div class="text-caption font-alt mt-8">
              Veuillez renseigner le code reçu par e-mail pour l'utilisateur
              <span class="font-weight-bold primary--text">{{ localUsername }}</span>.
              Une fois votre code renseigné vous serez invité à définir un mot de passe pour votre compte.
            </div>
          </v-col>
          <v-col cols="12" class="pt-0">
            <v-text-field v-model="localUsername" :maxlength="80" label="Votre email" outlined dense hide-details="auto" :rules="[$rules.email, $rules.required]" type="email" />
          </v-col>
          <v-col cols="12" class="pt-0">
            <v-text-field v-model="temporaryCode" :maxlength="10" label="Code recu par email" outlined hide-details="auto" :rules="[$rules.required, $rules.digit]" />
          </v-col>
          <v-col v-show="invalidCredentials" cols="12">
            <div class="error--text text-caption">Les identifiants sont invalides</div>
          </v-col>
          <v-col v-show="error" cols="12">
            <div class="error--text text-caption">{{ error }}</div>
          </v-col>
          <v-card-actions class="d-flex justify-end">
            <v-btn type="button" small :to="{name: 'login'}">Annuler</v-btn>
            <v-btn type="submit" small color="primary" :loading="loading">Valider</v-btn>
          </v-card-actions>
        </v-form>
      </v-card>
    </v-col>
  </v-row>
</template>

<script>
  import {validate} from '@/modules/auth/api'
  import {mapState} from 'vuex'

  export default {
    name: 'ValidateAccount',
    props: {
      username: String
    },
    data() {
      return {
        temporaryCode: null,
        invalidCredentials: false,
        error: null,
        loading: false
      }
    },
    computed: {
      ...mapState('layout', ['customDomain']),
      backGroundImage() {
        return this.customDomain !== '' ? '/img/assets/'+this.customDomain+'/login-default-bg.jpg' : require('@/assets/img/login-default-bg.png')
      },
      localUsername: {
        get() {
          return this.username ? this.username.toLowerCase() : null
        },
        set(value) {
          this.$emit('update:username', value)
        }
      }
    },
    methods: {
      async submit() {
        if (!this.$refs.validaccount.validate()) {
          return false
        }

        try {
          this.loading = true
          await validate(this.username, this.temporaryCode)
          this.$emit('validate', this.temporaryCode)
        } catch (error) {
          if (error.response.status === 401) {
            this.invalidCredentials = true
          } else {
            this.$root.$emit('snackbar:open', {
              snackbar: ()=> import('@/modules/core/components/Snackbar'),
              type: 'error',
              title: 'Les informations saisies sont invalides. Veuillez réessayer'
            })
          }
        } finally {
          this.loading = false
        }
      }
    }
  }
</script>
